.loading-container {
  height: 100vh;
  z-index: 9999;
  opacity: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.7);
}

.loading-spiner {
  width: 100px !important;
  height: 100px !important;
  top: 50%;
  left: 50%;
  position: fixed;
}
