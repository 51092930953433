.avatar {
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.strong{
  font-weight: 600;
}

.ant-modal-footer{
  display: none;
}