.danger-button {
  background-color: transparent !important;
  color: red !important;   
  
}
.danger-button:hover{
  background-color: red !important;
  color: rgb(252, 250, 250) !important; 
}

