:root {
  /* Colors */
  --white: #fff;
  --blue: #009bdf;

  /* BackgroundColors */
  --background-light-blue: #00bfff;
  --background-blue: #009bdf;
  --background-none: transparent;
  --background-white: white;
}

body {
  min-width: 1280px !important;
}

textarea {
  resize: none;
}

/* Custom Buttons */

.btn-primary-outline {
  background-color: #ffffff !important;
}
.ant-btn:last-child {
  margin-right: 15px;
}
/*.link-center{
    display:block;
    text-align: center;
  }

  /* Modals */

.ant-modal-footer {
  border-top: 0;
}

.ant-image {
  margin-right: 10px;
}

.space-no-gap {
  gap: 0px !important;
}

.row-volcano {
  background-color: rgba(255, 242, 232, 1);
}

.row-danger {
  background-color: rgba(241, 22, 14, 0.5);
}

.row-warning {
  background-color: rgba(252, 148, 49, 0.3);
}

.row-alert {
  background-color: rgba(252, 233, 113, 0.5);
}

.row-okay {
  background-color: rgba(00, 194, 123, 0.3);
}

.ant-input-number {
  width: 100%;
}

.ant-menu-dark .ant-menu-item-selected {
  background-color: #4dc9ff !important;
}

.gx-user-popover li {
  width: auto;
  text-align: right;
}

.export-table .ant-table-column-sorter {
  display: none;
}

/* 
Boton para eliminar un producto */

.danger-button-product {
  background-color: transparent !important;
  color: gray !important;
}
.danger-button-product:hover {
  background-color: transparent !important;
  color: red !important;
}

.export-btn {
  background-color: #5fba7c;
  color: #ffffff;
  margin: 0;
}

.export-btn:hover,
.export-btn:focus {
  color: #5fba7c;
  background-color: #ffffff;
  border-color: #5fba7c;
}
