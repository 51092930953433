:root {
  --gray-text: #9ca8ba;
  --white: #faf0e6;
}

.agenda .ant-table-thead .ant-table-cell {
  padding: 0 !important;
}

.container {
  width: 100%;
}

.agenda .ant-table-cell {
  width: 190px;
  background-color: white;
  text-align: center;
  color: var(--gray-text);
}

.agenda .ant-table-body .ant-table-cell {
  height: 120px;
  padding: 0;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}

.agenda .ant-table-thead .agenda .ant-table-cell-fix-left {
  width: 90px;
}

.agenda {
  color: var(--gray-text);
}

.agenda .anticon svg {
  width: 25px;
  color: var(--gray-text);
  height: 25px;
}

.agenda .locked svg {
  width: 35px;
  height: 35px;
  fill-opacity: 0.35;
}

.agenda .add svg {
  width: 35px;
  height: 35px;
  fill-opacity: 0.35;
}

.agenda .ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 0px;
}

.layout-agenda .ant-picker-calendar-date-content {
  height: auto !important;
}

.layout-agenda .ant-layout-sider {
  padding: 20px !important;
}

.layout-agenda .status-container {
  padding: 50px 10px;
}

.layout-agenda .ant-picker-calendar {
  text-align: center;
}

.layout-agenda .buttons-calendar {
  display: inline-flex;
}
