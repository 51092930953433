.logo {
  text-align: center;
  transition: all 0.3s;
  background-color: var(--background-blue);
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoM {
  height: 76px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-blue);
  width: 100%;
}

.moldearte-logo {
  width: 60%;
}

.moldearte-logoM {
  width: 80%;
  padding: 40px;
}

.base-color {
  background-color: var(--background-blue);
  color: #fff !important;
}

.base-color i {
  color: #fff;
}

.base-color span {
  color: #fff;
}

.checkin-button {
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

.ant-btn-link.checkin-button span {
  color: #fff;
}

.checkin-button:hover {
  background: transparent;
}

.gx-app-sidebar {
  background-color: var(--background-blue) !important;
}
