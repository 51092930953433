.ant-modal-title h2 {
  display: flex;
  font-weight: 500;
}


.ant-modal-title h2 span {
  margin-left: auto;
  margin-right: 20px;
}

.row {
  /* border-bottom: 1px solid gainsboro; */
  padding: 10px;
  margin: 0;
}

.row h4 {
  font-weight: 500;
}

.row:hover {
  background-color: ghostwhite;
  cursor: pointer;
}

.subtitle {
  color: var(--blue);
}

.products-details {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 400;
}

.products-details .title {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 400;
  text-align: center;
}

.products-details .order-products-header {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;
}

.products-details .order-products {
  text-align: center;
}