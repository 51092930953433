.header-item {
  width: 190px;
  text-align: center;
  border-radius: 0px;
  height: 100px;
}

.header-item .avatar {
  margin-left: auto;
  margin-right: auto;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-top: 0px;
}

.header-item .ant-card-cover {
  padding-top: 10px;
}

.header-item .ant-card-body {
  padding-top: 5px;
  padding-bottom: 10px;
}

.header-item .cabain-type{
  margin-bottom:0px;
  font-weight: 600;
  font-size:13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header-item .description {
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom:0px;
}
