.appointment-item {
  width: 100%;
  text-align: left;
  min-height: 120px;
}

.appointment-item .ant-card-body {
  padding: 5px 10px;
}

.appointment-item .patient {
  font-weight: 600;
  color: inherit;
}

.agenda-appointment .ant-card-head-wrapper {
  height: 15px;
  top: 25px;
  position: relative;
}

.appoitment-content {
  display: block;
  margin-bottom: 5px;
}

.appoitment-content.text-truncate {
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.appoitment-content.customer {
  font-weight: 600;
}

.appointment.buttons .ant-btn.ant-btn-link {
  margin: 0;
  padding: 0 8px;
}
