.add-btn {
  background-color: #2db7f5;
  color: #fff;
  width: 80%;
  margin: 20px auto;
  display: block;
}

.vertical-form-list {
  display: block;
}

.input-w100 {
  width: 100%;
}

.divider {
  border: none;
  border-bottom: 1px solid #ccc;
}
