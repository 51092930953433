.box-form-data-order {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.box-form-data-order .bx-style {
    padding: 15px 12px;
    border-radius: 10px;
}

.box-form-data-order .form-data-order {
    max-height: 100% !important;
    width: 35%;
}

