.login-container {
  padding: 40px;
  background-color: var(--background-white);
  width: 400px;
  max-width: calc(100% - 80px);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 25%);
  margin: 0 auto;
  border-radius: 15px;
}

.logo-login {
  text-align: center;
  margin-bottom: 40px;
}

.col-login {
  padding: 14rem 10rem;
  display: flex;
  align-items: center;
  height: calc(100vh - 70px);
  vertical-align: middle;
}

.login-form-forgot {
  float: right;
}

@media (max-width: 991.98px) {
  .col-login {
    padding: 2rem 1rem;
  }
}

.prefix-icon-wrapper {
  position: absolute;
  z-index: 1;
  width: -3rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-body .ant-select-selection-placeholder,
.login-body .ant-select-selection-item,
.login-body .ant-select-selection-search {
  margin-left: 25px;
}

#normal_login_branchId > .ant-select-selection-search-input {
  padding-left: calc(5rem - 8px);
}
