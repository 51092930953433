.forgot-container {
    padding: 40px;
    background-color: var(--background-white);
    width: 400px;
    max-width: calc(100% - 80px);
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 25%);
    margin: 0 auto;
    border-radius: 15px;
  }
  
  .logo-forgot {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .col-forgot {
    padding: 14rem 10rem;
    display: flex;
    align-items: center;
    height: calc(100vh - 70px);
    vertical-align: middle;
  }
  
  .forgot-form-login {
    float: right;
  }
  
  @media (max-width: 991.98px) {
    .col-forgot {
      padding: 2rem 1rem;
    }
  }